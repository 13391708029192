import React from "react";
import PaymentV1 from "./paymentV1";
import PaymentV2 from "./paymentV2";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const PaymentSummary = () => {
  return isZakat2025Enabled ? <PaymentV2 /> : <PaymentV1 />;
};

export default PaymentSummary;
