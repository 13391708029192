import AgricultureAssetsPage from "../pages/agricultureAssets";
import AlternativeInvestments from "../pages/alternativeInvestments";
import BankAccount from "../pages/bankAccount";
import BusinessAssets from "../pages/businessAssets";
import Cash from "../pages/cash";
import ChildTrustFund from "../pages/childTrustFund";
import GoldAndSilverPage from "../pages/goldAndSilver";
import Crypto from "../pages/crypto";
import OwedAssets from "../pages/owedAssets";
import Pensions from "../pages/pensions";
import Property from "../pages/property";
import Shares from "../pages/shares";
import Funds from "../pages/funds";
import Debts from "../pages/debt";
import { PageType } from "../types";
import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";
import {
  assetTypeToPageLayoutConfig,
  liabilityTypeToPageLayoutConfig,
} from "../components/consts";

interface Page {
  label: string;
  page: React.FC<PageType>;
  hideLabel?: boolean;
}

export type PageKeys = ZakatAssetType | "DEBTS";

export const pages: Record<PageKeys, Page> = {
  DEBTS: {
    label: liabilityTypeToPageLayoutConfig.DEBTS.title,
    page: Debts,
  },
  CASH: {
    label: assetTypeToPageLayoutConfig.CASH.title,
    page: Cash,
  },
  BANK: {
    label: assetTypeToPageLayoutConfig.BANK.title,
    page: BankAccount,
  },
  GOLD_SILVER: {
    label: assetTypeToPageLayoutConfig.GOLD_SILVER.title,
    page: GoldAndSilverPage,
  },
  CHILD_TRUST_FUND: {
    label: assetTypeToPageLayoutConfig.CHILD_TRUST_FUND.title,
    page: ChildTrustFund,
  },
  SHARES: {
    label: "Shares & Funds",
    page: Shares,
  },
  FUNDS: {
    label: "Funds",
    page: Funds,
    hideLabel: true,
  },
  PENSIONS: {
    label: assetTypeToPageLayoutConfig.PENSIONS.title,
    page: Pensions,
  },
  CRYPTO: {
    label: assetTypeToPageLayoutConfig.CRYPTO.title,
    page: Crypto,
  },
  PROPERTIES: {
    label: assetTypeToPageLayoutConfig.PROPERTIES.title,
    page: Property,
  },
  BUSINESS: {
    label: assetTypeToPageLayoutConfig.BUSINESS.title,
    page: BusinessAssets,
  },
  AGRICULTURE_ASSETS: {
    label: assetTypeToPageLayoutConfig.AGRICULTURE_ASSETS.title,
    page: AgricultureAssetsPage,
  },
  ASSETS_OWED: {
    label: assetTypeToPageLayoutConfig.ASSETS_OWED.title,
    page: OwedAssets,
  },
  OTHER: {
    label: assetTypeToPageLayoutConfig.OTHER.title,
    page: AlternativeInvestments,
  },
} as Record<PageKeys, Page>;
