import React, { useContext, useState } from "react";
import { CardsContainer } from "../styled";
import { PageType } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@components/mediaQueries";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import Modal from "@legacyComponents/Modal/Modal.component";
import PortfolioAssetForm from "./PortfolioAssetForm";
import { CurrencyContext } from "@components/CurrencyContext";
import { zakatAssetsSelector } from "@Zakat/store/zakatV2/zakat.selector";
import { deleteZakatAssetAsync } from "@Zakat/store/zakatV2/zakat.action";
import { ZakatAssetType, ZakatAsset } from "@ifgengineering/client-invest-sdk";
import { PortfolioAssetFormData } from "./types";
import PortfolioFormLayout from "./PortfolioFormLayout";
import { assetTypeToPageLayoutConfig } from "./consts";

interface PortfolioAssetPageProps extends PageType {
  assetType: ZakatAssetType;
}

const emptyState: PortfolioAssetFormData = {
  name: "",
  description: "",
  amount: 0,
};

const PortfolioAssetPage: React.FC<PortfolioAssetPageProps> = ({
  nextPage,
  previousPage,
  assetType,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] =
    useState<PortfolioAssetFormData>(emptyState);
  const zakatAssets = useSelector(zakatAssetsSelector);
  const assetTypeAssets = zakatAssets?.filter(
    (asset) => asset.type === assetType
  );
  const pageLayoutConfig = assetTypeToPageLayoutConfig[assetType];

  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const handleDelete = async (value: ZakatAsset) => {
    if (value.id) {
      await dispatch(deleteZakatAssetAsync(value.id.toString()));
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedAsset(emptyState);
  };

  const assetForm = (
    <PortfolioAssetForm
      data={selectedAsset}
      assetType={assetType}
      previousPage={previousPage}
      onSubmit={onClose}
    />
  );

  return (
    <>
      {(!open || !isMobile) && (
        <PortfolioFormLayout
          title={pageLayoutConfig.title}
          description={pageLayoutConfig.description}
          tooltipText={pageLayoutConfig.tooltipText}
          showTooltip={true}
          onNext={nextPage}
          onPrevious={previousPage}
          showBackButton={true}
        >
          <CardsContainer>
            <AddItemCard
              text="Add item"
              onClick={() => {
                setSelectedAsset(emptyState);
                setOpen(true);
              }}
            />
            {assetTypeAssets?.map((asset, index) => (
              <AccountCard
                key={index}
                name={asset.name || ""}
                currency={currencySymbol}
                balance={asset.amount || 0}
                onClickDelete={() => handleDelete(asset)}
                onClickEdit={() => {
                  setSelectedAsset(asset as PortfolioAssetFormData);
                  setOpen(true);
                }}
              />
            ))}
          </CardsContainer>
        </PortfolioFormLayout>
      )}
      {isMobile && open ? (
        assetForm
      ) : (
        <Modal onClose={onClose} open={open} closeOnOutsideClick={true}>
          {assetForm}
        </Modal>
      )}
    </>
  );
};

export default PortfolioAssetPage;
