import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Assets from "@Zakat/components/PortfolioDataV2";
import Dua from "@Zakat/pages/payment/dua";
import NavigationLayout from "@Zakat/layouts/navigationLayout";
import PrivateRoute from "@Zakat/components/PrivateRoute";
import ZakatSummaryPage from "./summary";
import PaymentSummary from "./payment";
import { ZakatSummaryPageProvider } from "@Zakat/pages/summary/ZakatSummaryPageProvider";
import ZakatGiftAidPage from "./giftaid/ZakatGiftAidPage";
import CharityAllocationPage from "./charity-allocation";
import RedirectCheckout from "@Zakat/components/Payment/redirect-checkout";
import { navigate } from "gatsby";
import Loading from "@components/Loading";
import SecurityLayer from "@ifgengineering/client-security-layer";
import authSdk from "@components/Login/authSDK";
import { useSelector } from "react-redux";
import { getUserSelector } from "../store/user/user.selectors";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import ZakatTopUp from "./top-up";

const { refreshAccessToken, userInfo } = authSdk;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
`;

export const App: React.FC = () => {
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (user.email) {
      Sentry.setUser({ email: user.email });
    }
  }, [user]);

  return (
    <ZakatSummaryPageProvider>
      <PageWrapper>
        <SecurityLayer
          // @ts-expect-error refreshAccessToken is not properly typed
          refreshAccessToken={refreshAccessToken}
          // @ts-expect-error userInfo is not properly typed
          userInfo={userInfo}
          failureCallback={() => navigate("/auth/login/")}
          loadingComponent={<Loading />}
          userInfoSuccessCallback={(user) => user}
        >
          <Router component={NavigationLayout} basepath="/app">
            <PrivateRoute path="/calculate" component={Assets} />
            <PrivateRoute path="/summary" component={ZakatSummaryPage} />
            <PrivateRoute path="/top-up" component={ZakatTopUp} />
            <PrivateRoute
              path="/charity-allocation"
              component={CharityAllocationPage}
            />
            <PrivateRoute path="/gift-aid" component={ZakatGiftAidPage} />
            <PrivateRoute path="/payment" component={PaymentSummary} />
            <PrivateRoute path="/dua" component={Dua} />
            <PrivateRoute
              path="/redirect-checkout"
              component={RedirectCheckout}
            />
          </Router>
        </SecurityLayer>
      </PageWrapper>
    </ZakatSummaryPageProvider>
  );
};

export default App;
