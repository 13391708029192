import React from "react";
import { PageType } from "../../types";
import PortfolioLiabilityPage from "../../components/PortfolioLiabilityPage";

const DebtsPage: React.FC<PageType> = ({ nextPage, previousPage }) => {
  return (
    <PortfolioLiabilityPage nextPage={nextPage} previousPage={previousPage} />
  );
};

export default DebtsPage;
