import React, { useContext } from "react";
import { PageType } from "../types";
import { Field, Formik, ErrorMessage as FormikError } from "formik";
import { FieldInputProps } from "formik";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import { useDispatch, useSelector } from "react-redux";
import { CashObject } from "../constants/types";
import { FormikForm } from "@legacyComponents/Form/input/styled/Form";
import { zakatAssetsSelector } from "@Zakat/store/zakatV2/zakat.selector";
import {
  updateZakatAssetAsync,
  createZakatAssetAsync,
} from "@Zakat/store/zakatV2/zakat.action";
import PortfolioFormLayout from "../components/PortfolioFormLayout";
import { CurrencyContext } from "@components/CurrencyContext";
import { ErrorMessage } from "../styled";
import { CashSchema } from "../components/validationSchema";

const Cash: React.FC<PageType> = ({ nextPage, previousPage }) => {
  const dispatch = useDispatch();
  const zakatAssets = useSelector(zakatAssetsSelector);
  const { currencySymbol } = useContext(CurrencyContext);

  const cashList = zakatAssets?.filter((asset) => asset.type === "CASH");

  const cashTypeNamesMap = {
    cashInHand: "Cash in Hand",
    cashISA: "Cash in ISA",
  };

  const initialState = {
    cashInHand:
      cashList?.find((item) => item.name === cashTypeNamesMap.cashInHand)
        ?.amount || 0,
    cashISA:
      cashList?.find((item) => item.name === cashTypeNamesMap.cashISA)
        ?.amount || 0,
  };

  const createOrUpdateCash = async ({
    type,
    amount,
    assetId,
  }: {
    type: "cashInHand" | "cashISA";
    amount: number;
    assetId: string;
  }) => {
    if (assetId) {
      await dispatch(
        updateZakatAssetAsync({
          assetId,
          data: {
            name: cashTypeNamesMap[type],
            amount,
          },
        })
      );
    } else {
      await dispatch(
        createZakatAssetAsync({
          amount,
          name: cashTypeNamesMap[type],
          type: "CASH",
        })
      );
    }
  };

  const handleSubmit = async (values: CashObject) => {
    await createOrUpdateCash({
      type: "cashInHand",
      amount: values.cashInHand || 0,
      assetId:
        cashList?.find((item) => item.name === cashTypeNamesMap.cashInHand)
          ?.id || "",
    });

    await createOrUpdateCash({
      type: "cashISA",
      amount: values.cashISA || 0,
      assetId:
        cashList?.find((item) => item.name === cashTypeNamesMap.cashISA)?.id ||
        "",
    });
    nextPage();
  };

  return (
    <Formik
      validationSchema={CashSchema}
      initialValues={initialState}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <PortfolioFormLayout
          title="Cash"
          description="Enter the amount of cash you have in paper currency"
          onPrevious={previousPage}
          submitButtonText="Save & Continue"
          isFormik={true}
          showBackButton={true}
          assetType="CASH"
        >
          <Field name="cashInHand">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <MoneyInput
                  {...field}
                  heading="Cash in Hand"
                  description="The amount of cash you have in paper currency"
                  currency={currencySymbol}
                  value={field.value?.toString() || ""}
                  onChange={(value) => {
                    field.onChange({
                      target: {
                        name: field.name,
                        value: value ? parseFloat(value.replace(/,/g, "")) : "",
                      },
                    });
                  }}
                />
                <FormikError name="cashInHand" component={ErrorMessage} />
              </>
            )}
          </Field>

          <Field name="cashISA">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <MoneyInput
                  {...field}
                  heading="Cash in ISA"
                  description="Cash held in a government backed cash ISA"
                  currency={currencySymbol}
                  value={field.value?.toString() || ""}
                  onChange={(value) => {
                    field.onChange({
                      target: {
                        name: field.name,
                        value: value ? parseFloat(value.replace(/,/g, "")) : "",
                      },
                    });
                  }}
                />
                <FormikError name="cashISA" component={ErrorMessage} />
              </>
            )}
          </Field>
        </PortfolioFormLayout>
      </FormikForm>
    </Formik>
  );
};

export default Cash;
