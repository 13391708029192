import React from "react";
import { PageType } from "../../types";
import PortfolioAssetPage from "../../components/PortfolioAssetPage";

const AgricultureAssetsPage: React.FC<PageType> = ({
  nextPage,
  previousPage,
}) => {
  return (
    <PortfolioAssetPage
      nextPage={nextPage}
      previousPage={previousPage}
      assetType="AGRICULTURE_ASSETS"
    />
  );
};

export default AgricultureAssetsPage;
