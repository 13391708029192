import React, { useContext, useState } from "react";
import { CardsContainer } from "../styled";
import { PageType } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@components/mediaQueries";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import Modal from "@legacyComponents/Modal/Modal.component";
import { CurrencyContext } from "@components/CurrencyContext";
import { zakatLiabilitiesSelector } from "@Zakat/store/zakatV2/zakat.selector";
import { PortfolioLiabilityFormData } from "./types";
import PortfolioFormLayout from "./PortfolioFormLayout";
import { liabilityTypeToPageLayoutConfig } from "./consts";
import PortfolioLiabilityForm from "./PortfolioLiabilityForm";
import { deleteZakatLiabilityAsync } from "../../../store/zakatV2/zakat.action";
import { ZakatLiability } from "@ifgengineering/client-invest-sdk";

type PortfolioLiabilityPageProps = PageType;

const emptyState: PortfolioLiabilityFormData = {
  name: "",
  description: "",
  amount: 0,
  type: "MORTGAGE",
};

const PortfolioLiabilityPage: React.FC<PortfolioLiabilityPageProps> = ({
  nextPage,
  previousPage,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedLiability, setSelectedLiability] =
    useState<PortfolioLiabilityFormData>(emptyState);
  const zakatLiabilities = useSelector(zakatLiabilitiesSelector);

  const pageLayoutConfig = liabilityTypeToPageLayoutConfig["DEBTS"];

  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const handleDelete = async (value: ZakatLiability) => {
    if (value.id) {
      await dispatch(deleteZakatLiabilityAsync(value.id.toString()));
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedLiability(emptyState);
  };

  const liabilityForm = (
    <PortfolioLiabilityForm
      data={selectedLiability}
      previousPage={previousPage}
      onSubmit={onClose}
    />
  );

  return (
    <>
      {(!open || !isMobile) && (
        <PortfolioFormLayout
          title={pageLayoutConfig.title}
          description={pageLayoutConfig.description}
          tooltipText={pageLayoutConfig.tooltipText}
          showTooltip={true}
          onNext={nextPage}
          onPrevious={previousPage}
          showBackButton={true}
        >
          <CardsContainer>
            <AddItemCard
              text="Add item"
              onClick={() => {
                setSelectedLiability(emptyState);
                setOpen(true);
              }}
            />
            {zakatLiabilities?.map((liability, index) => (
              <AccountCard
                key={index}
                name={liability.name || ""}
                currency={currencySymbol}
                balance={liability.amount || 0}
                onClickDelete={() => handleDelete(liability)}
                onClickEdit={() => {
                  setSelectedLiability({
                    id: liability.id,
                    name: liability.name || "",
                    description: liability.description || "",
                    amount: liability.amount || 0,
                    type: liability.type || undefined,
                  });
                  setOpen(true);
                }}
              />
            ))}
          </CardsContainer>
        </PortfolioFormLayout>
      )}
      {isMobile && open ? (
        liabilityForm
      ) : (
        <Modal onClose={onClose} open={open} closeOnOutsideClick={true}>
          {liabilityForm}
        </Modal>
      )}
    </>
  );
};

export default PortfolioLiabilityPage;
