export const zakatTabs: { id: string; title: string }[] = [
  {
    title: "Calculate",
    id: "calculate",
  },
  {
    title: "Summary",
    id: "summary",
  },
  {
    title: "Charity Allocation",
    id: "charity-allocation",
  },
  {
    title: "Top-up",
    id: "top-up",
  },
  {
    title: "Gift Aid (Tax)",
    id: "gift-aid",
  },
  {
    title: "Payment Summary",
    id: "payment",
  },
];
