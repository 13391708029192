import Loading from "@components/Loading";
import { getUserEmail } from "@components/Login/selectors";
import { ZakatPaymentCheckout } from "@Zakat/helpers/zakat/redirectCheckout";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const RedirectCheckout: React.FC = () => {
  const userEmail = useSelector(getUserEmail);

  useEffect(() => {
    if (userEmail) {
      ZakatPaymentCheckout({
        userEmail,
        cancelUrl: `${location.origin}/app/dua`,
        successUrl: `${location.origin}/referral`,
      });
    }
  }, [userEmail]);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default RedirectCheckout;
