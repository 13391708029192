import React from "react";
import { PageType } from "../../types";
import PortfolioAssetPage from "../../components/PortfolioAssetPage";

const SharesPage: React.FC<PageType> = ({ nextPage, previousPage }) => {
  return (
    <PortfolioAssetPage
      nextPage={nextPage}
      previousPage={previousPage}
      assetType="SHARES"
    />
  );
};

export default SharesPage;
