import React from "react";
import PreFetchDataV1 from "../PreFetchData/PreFetchData.components";
import PreFetchDataV2 from "../PreFetchData/PrefetchDataV2.component";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

export type PrivateRouteProps = {
  path: string;
  component: React.FC;
};

const PreFetchData = isZakat2025Enabled ? PreFetchDataV2 : PreFetchDataV1;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  return (
    <PreFetchData>
      <Component {...rest}>{children}</Component>
    </PreFetchData>
  );
};

export default PrivateRoute;
