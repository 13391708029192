import * as Yup from "yup";

export const PortfolioAssetSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  amount: Yup.number()
    .required("Amount is required")
    .moreThan(0, "Amount must be greater than 0"),
});

export const PortfolioLiabilitySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  amount: Yup.number()
    .required("Amount is required")
    .moreThan(0, "Amount must be greater than 0"),
});

export const CashSchema = Yup.object().shape({
  cashInHand: Yup.number(),
  cashISA: Yup.number(),
});
