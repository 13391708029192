import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";
import { FormLayoutConfig } from "./types";
import {
  assetTypeToTooltipText,
  liabilityTypeToTooltipText,
} from "../constants/tooltipText";

export const assetTypeToPageLayoutConfig: Record<
  ZakatAssetType,
  FormLayoutConfig
> = {
  GOLD_SILVER: {
    title: "Gold & Silver",
    description:
      "Include any precious items like gold or silver. For jewellery, only count the gold and silver content.",
    tooltipText: assetTypeToTooltipText.GOLD_SILVER,
  },
  AGRICULTURE_ASSETS: {
    title: "Agriculture Assets",
    description:
      "If you have farmland, crops or livestock you can add it here.",
    tooltipText: assetTypeToTooltipText.AGRICULTURE_ASSETS,
  },
  ASSETS_OWED: {
    title: "Assets Owed To You",
    description:
      "If you have lent anybody or institution money or other assets that they now owe you, you can add it here.",
    tooltipText: assetTypeToTooltipText.ASSETS_OWED,
  },
  BANK: {
    title: "Bank Accounts",
    description: "List out any bank accounts you may have. E.g. Barclays.",
    tooltipText: assetTypeToTooltipText.BANK,
  },
  BUSINESS: {
    title: "Business Assets",
    description:
      "Business assets include Cash, Receivables (money owed to the business) and inventory. Inventory should be valued at the retail price.",
    tooltipText: assetTypeToTooltipText.BUSINESS,
  },
  CASH: {
    title: "Cash",
    description: "Enter the amount of cash you have in paper currency.",
    tooltipText: assetTypeToTooltipText.CASH,
  },
  CHILD_TRUST_FUND: {
    title: "Child Trust Fund",
    description:
      "This is an investment you’ve set up on behalf of your child. E.g. a Junior ISA.",
    tooltipText: assetTypeToTooltipText.CHILD_TRUST_FUND,
  },
  CRYPTO: {
    title: "Crypto",
    description:
      "These are any cryptocurrency holdings you have. E.g. Bitcoin.",
    tooltipText: assetTypeToTooltipText.CRYPTO,
  },
  CUR8_PORTFOLIO: {
    title: "Cur8 Portfolio",
    description: "Add your cur8 portfolio assets",
    tooltipText: assetTypeToTooltipText.CUR8_PORTFOLIO,
  },
  OTHER: {
    title: "Other Investments",
    description:
      "If you have any other assets or investments that you did not add, you can add them here. E.g. SME Lending.",
    tooltipText: assetTypeToTooltipText.OTHER,
  },
  PENSIONS: {
    title: "Pensions",
    description:
      "These are any retirement pensions you have, government or private.",
    tooltipText: assetTypeToTooltipText.PENSIONS,
  },
  PROPERTIES: {
    title: "Properties",
    description:
      "Enter the value of your properties you have including your primary residence, other property such as your second home and any buy-to-let properties",
    tooltipText: assetTypeToTooltipText.PROPERTIES,
  },
  SHARES: {
    title: "Shares",
    description:
      "Add any investments you have made in the public stock markets.",
    tooltipText: assetTypeToTooltipText.SHARES,
  },
  FUNDS: {
    title: "Funds",
    description:
      "Add any investments you have made in the public stock markets.",
    tooltipText: assetTypeToTooltipText.FUNDS,
  },
};

export const liabilityTypeToPageLayoutConfig: Record<
  "DEBTS",
  FormLayoutConfig
> = {
  DEBTS: {
    title: "Debts",
    description:
      "This is any money that you owe. Only add what is specified in more info.",
    tooltipText: liabilityTypeToTooltipText.DEBTS,
  },
};
