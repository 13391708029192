import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isAuthenticatedSelector,
  neverLoadedAuthSelector,
} from "@Zakat/store/auth/auth.selector";
import { fetchUserInfoAPI } from "@components/Login/actions";
import { CurrencyContext } from "@components/CurrencyContext";
import { neverLoadedZakatSelector } from "@Zakat/store/zakatV2/zakat.selector";
import {
  fetchNisabAsync,
  fetchZakatAsync,
} from "@Zakat/store/zakatV2/zakat.action";
import { neverLoadedInvestorSelector } from "@Zakat/store/investor/investor.selector";
import { fetchInvestorAsync } from "@Zakat/store/investor/investor.action";

const PreFetchDataV2: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { setCurrency } = useContext(CurrencyContext);

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const neverLoadedAuth = useSelector(neverLoadedAuthSelector);
  const neverLoadedZakat = useSelector(neverLoadedZakatSelector);
  const neverLoadedInvestor = useSelector(neverLoadedInvestorSelector);

  const fetch = async () => {
    if (!isAuthenticated) {
      return;
    }

    if (neverLoadedAuth) {
      await dispatch(fetchUserInfoAPI());
    }

    if (neverLoadedZakat) {
      // @ts-expect-error unwrap is not typed
      const data = await dispatch(fetchZakatAsync()).unwrap();
      if (data) {
        setCurrency(data.currency);
      }

      await dispatch(fetchNisabAsync());
    }

    if (neverLoadedInvestor) {
      await dispatch(fetchInvestorAsync());
    }
  };

  const loadData = async () => {
    await fetch();
  };

  useEffect(() => {
    loadData();
  }, [isAuthenticated]);

  return <>{children}</>;
};

export default PreFetchDataV2;
