import React from "react";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import LockIcon from "@assets/icons/lock.svg";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@components/mediaQueries";

interface PortfolioFormButtonProps {
  onNext?: () => void;
  onPrevious?: () => void;
  submitButtonText: string;
  isFormik: boolean;
  showBackButton?: boolean;
}

const PortfolioFormButton = ({
  onNext,
  onPrevious,
  submitButtonText,
  isFormik,
  showBackButton = false,
}: PortfolioFormButtonProps) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const shouldShowBackButton = showBackButton || isMobile ? "Back" : undefined;

  const privacyDisclaimerText = (
    <div>
      We take your{" "}
      <a
        href="https://www.islamicfinanceguru.com/security-policy"
        target="_blank"
        rel="noreferrer"
      >
        privacy seriously
      </a>
      . Our institutional-grade security ensures your data is confidential.
    </div>
  );

  return (
    <FormButton
      onClick={onNext}
      text={submitButtonText}
      type={isFormik ? "submit" : "button"}
      disclaimerIcon={<LockIcon />}
      disclaimerText={privacyDisclaimerText}
      leftArrowOnClick={onPrevious}
      leftArrowLabel={shouldShowBackButton}
    />
  );
};

export default PortfolioFormButton;
