import { ZakatLiabilityType } from "@ifgengineering/client-invest-sdk";

export const LiabilityLabels: Record<ZakatLiabilityType, string> = {
  MORTGAGE: "Mortgage",
  STUDENT_LOAN: "Student Loan",
  MONEY_BORROWED: "Money Borrowed",
  CREDIT_CARD: "Credit Card",
  CAR_FINANCE: "Car Finance",
  BANK_LOAN: "Bank Loan",
  OTHER: "Other Debts",
};

export const getLiabilityTypesList = () => {
  return Object.keys(LiabilityLabels).map((type) => ({
    value: type,
    label: LiabilityLabels[type as ZakatLiabilityType],
  }));
};
