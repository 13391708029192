import React, { useContext } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage as FormikError,
  FieldInputProps,
} from "formik";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import { useDispatch } from "react-redux";
import { CurrencyContext } from "@components/CurrencyContext";
import {
  createZakatAssetAsync,
  updateZakatAssetAsync,
} from "@Zakat/store/zakatV2/zakat.action";
import PortfolioFormLayout from "./PortfolioFormLayout";
import { ErrorMessage } from "../styled";
import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";
import { PortfolioAssetFormData } from "./types";
import { assetTypeToFormInputConfig, getFormLayoutConfig } from "./formConfigs";
import { PortfolioAssetSchema } from "./validationSchema";

export interface PortfolioAssetFormProps {
  data: PortfolioAssetFormData;
  assetType: ZakatAssetType;
  previousPage: () => void;
  onSubmit: () => void;
}

const PortfolioAssetForm = ({
  data,
  assetType,
  onSubmit,
  previousPage,
}: PortfolioAssetFormProps): JSX.Element => {
  const formLayoutConfig = getFormLayoutConfig(assetType);
  const formInputConfig = assetTypeToFormInputConfig[assetType];

  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const handleSubmit = async (values: PortfolioAssetFormData) => {
    if (values.id) {
      await dispatch(
        updateZakatAssetAsync({
          assetId: values.id.toString(),
          data: {
            name: values.name,
            description: values.description,
            amount: values.amount,
          },
        })
      );
    } else {
      await dispatch(
        createZakatAssetAsync({
          name: values.name,
          description: values.description,
          amount: values.amount,
          type: assetType,
        })
      );
    }
    onSubmit();
  };

  return (
    <Formik
      validationSchema={PortfolioAssetSchema}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      <Form>
        <PortfolioFormLayout
          title={formLayoutConfig.title}
          description={formLayoutConfig.description}
          showTooltip={false}
          onPrevious={previousPage}
          submitButtonText="Save & Continue"
          isFormik={true}
          assetType={assetType}
        >
          <Field name="name">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  heading={formInputConfig.name.title}
                  description={formInputConfig.name.description}
                  placeholder={formInputConfig.name.placeholder}
                />
                <FormikError name="name" component={ErrorMessage} />
              </>
            )}
          </Field>

          <Field name="description">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  heading={formInputConfig.description?.title}
                  description={formInputConfig.description?.description}
                  placeholder={formInputConfig.description?.placeholder}
                />
                <FormikError name="description" component={ErrorMessage} />
              </>
            )}
          </Field>

          <Field name="amount">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <MoneyInput
                  {...field}
                  heading={formInputConfig.amount?.title}
                  description={formInputConfig.amount?.description}
                  currency={currencySymbol}
                  value={field.value?.toString() || ""}
                  onChange={(value) => {
                    field.onChange({
                      target: {
                        name: field.name,
                        value: value ? parseFloat(value.replace(/,/g, "")) : "",
                      },
                    });
                  }}
                />
                <FormikError name="amount" component={ErrorMessage} />
              </>
            )}
          </Field>
        </PortfolioFormLayout>
      </Form>
    </Formik>
  );
};

export default PortfolioAssetForm;
