import React, { ReactNode } from "react";
import { Description, InnerWrapper, Layout, Text } from "../styled";
import Tooltip from "@components/Tooltip";
import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";
import { assetTypeToTooltipText } from "../constants/tooltipText";
import PortfolioFormButton from "./PortfolioFormButton";

interface PortfolioFormLayoutProps {
  title: string;
  description?: string;
  tooltipText?: string;
  showTooltip?: boolean;
  children: ReactNode;
  onNext?: () => void;
  onPrevious?: () => void;
  submitButtonText?: string;
  isFormik?: boolean;
  showBackButton?: boolean;
  assetType?: ZakatAssetType;
}

const PortfolioFormLayout: React.FC<PortfolioFormLayoutProps> = ({
  title,
  description,
  tooltipText,
  showTooltip,
  children,
  onNext,
  onPrevious,
  submitButtonText = "Save & Continue",
  isFormik = false,
  showBackButton,
  assetType,
}) => {
  const theTooltipText = assetType
    ? assetTypeToTooltipText[assetType]
    : tooltipText;
  const button = (
    <PortfolioFormButton
      onNext={onNext}
      onPrevious={onPrevious}
      submitButtonText={submitButtonText}
      isFormik={isFormik}
      showBackButton={showBackButton}
    />
  );

  return (
    <Layout>
      <InnerWrapper>
        <div>
          <Text align="left">{title}</Text>
          {description && <Description>{description}</Description>}
          {showTooltip && theTooltipText && <Tooltip text={theTooltipText} />}
        </div>
        {children}
      </InnerWrapper>
      <InnerWrapper>{button}</InnerWrapper>
    </Layout>
  );
};

export default PortfolioFormLayout;
