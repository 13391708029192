import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";
import { FormInputConfig, FormLayoutConfig } from "./types";
import { assetTypeToPageLayoutConfig } from "./consts";

export const defaultFormInputConfig: FormInputConfig = {
  name: {
    title: "Name of Asset",
    description: "Enter the details of the asset",
  },
  description: {
    title: "Description",
    description: "Add any additional details",
  },
  amount: { title: "Amount", description: "Enter the value of this asset" },
};

export const goldAndSilverFormInputConfig: FormInputConfig = {
  name: {
    title: "Name of Asset",
    description: "e.g My gold coins",
  },
  description: {
    title: "Description",
    description: "e.g gift from my parents",
  },
  amount: {
    title: "Estimated Value",
    description: "How much you think your gold is worth",
  },
};

export const agricultureAssetsFormInputConfig: FormInputConfig = {
  name: {
    title: "Name of Asset",
    description: "e.g My farm",
  },
  description: {
    title: "Description",
    description: "e.g. my 20 hectare farmland in South-East England",
  },
  amount: {
    title: "Estimated Value",
    description: "How much you think your agriculture assets are worth",
  },
};

export const cryptoFormInputConfig: FormInputConfig = {
  name: {
    title: "Name of Coin",
    description: "e.g. Bitcoin. You can also put the ticker symbol e.g. $BTC",
  },
  description: {
    title: "Description",
    description: "e.g. my 1000 BTC",
  },
  amount: {
    title: "Estimated Value",
    description: "How much you think your coins are worth",
  },
};

export const childTrustFundFormInputConfig: FormInputConfig = {
  name: {
    title: "Name of Fund",
    description: "e.g. my child’s junior ISA held with AJ Bell",
  },
  description: {
    title: "Description",
    description: "e.g. Ahamd's or Summaya's junior ISA held with AJ Bell",
  },
  amount: {
    title: "Estimated Value",
    description: "How much you think the fund is worth",
  },
};

export const assetTypeToFormInputConfig: Record<
  ZakatAssetType,
  FormInputConfig
> = {
  AGRICULTURE_ASSETS: agricultureAssetsFormInputConfig,
  ASSETS_OWED: defaultFormInputConfig,
  BANK: defaultFormInputConfig,
  BUSINESS: defaultFormInputConfig,
  CASH: defaultFormInputConfig,
  CHILD_TRUST_FUND: childTrustFundFormInputConfig,
  CRYPTO: cryptoFormInputConfig,
  CUR8_PORTFOLIO: defaultFormInputConfig,
  FUNDS: defaultFormInputConfig,
  GOLD_SILVER: goldAndSilverFormInputConfig,
  OTHER: defaultFormInputConfig,
  PENSIONS: defaultFormInputConfig,
  PROPERTIES: defaultFormInputConfig,
  SHARES: defaultFormInputConfig,
};

export const getFormLayoutConfig = (
  assetType: ZakatAssetType
): FormLayoutConfig => {
  return {
    title: `Add ${assetTypeToPageLayoutConfig[assetType].title}`,
    description: assetTypeToPageLayoutConfig[assetType].description,
  };
};
