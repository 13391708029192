import React, { useState, useEffect, useMemo } from "react";
import {
  Description,
  InnerWrapper,
  Layout,
  Spacer,
  Text,
} from "@components/PortfolioData/styled";
import { pages } from "./constants/pages";
import CheckboxButton, {
  CheckboxButtonDirection,
} from "@legacyComponents/CheckboxButton/CheckboxButton.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@components/mediaQueries";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import Currency from "@Zakat/components/PortfolioDataV2/pages/currency";
import Tooltip from "@components/Tooltip";
import { zakatAssetsSelector } from "@Zakat/store/zakatV2/zakat.selector";
import { getAssetsWithValue } from "./helpers";
import { ZakatAssetType } from "@ifgengineering/client-invest-sdk";

interface AssetsProps {
  showTooltip?: boolean;
  showOverallEstatePage?: boolean;
  showCurrencyPage?: boolean;
  forceAssetsItems?: boolean;
  destinationPage: string;
  originPage: string;
}

const PortfolioDataV2: React.FC<AssetsProps> = ({
  showTooltip = false,
  destinationPage,
  originPage,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const zakatAssets = useSelector(zakatAssetsSelector);

  const portfolioList = useMemo(
    () => getAssetsWithValue(zakatAssets || []),
    [zakatAssets]
  );

  type AssetTypes = ZakatAssetType | "DEBTS" | "OTHER";

  const defaultAssets: AssetTypes[] = ["DEBTS", "OTHER"];
  const [selectedAssets, setSelectedAssets] =
    useState<AssetTypes[]>(defaultAssets);
  const [page, setPage] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showChooseCurrencyPage, setShowChooseCurrencyPage] =
    useState<boolean>(true);

  useEffect(() => {
    if (portfolioList.length > 0) {
      setSelectedAssets((prev) => {
        const combinedSelection = [...new Set([...prev, ...portfolioList])];
        // Only update if there are actual changes
        return JSON.stringify(combinedSelection.sort()) !==
          JSON.stringify(prev.sort())
          ? combinedSelection
          : prev;
      });
    }
  }, [portfolioList]);

  const assetArray = Object.keys(pages) as (ZakatAssetType | "DEBTS")[];

  const buttonDefinitions = () =>
    assetArray
      .filter((asset) => !pages[asset].hideLabel)
      .map((asset) => ({
        key: `button-${asset}`,
        text: pages[asset].label,
        value: asset,
        disabled: asset === "DEBTS" || asset === "OTHER",
      }));

  const nextPage: (currentPage: number) => void = (currentPage) => {
    !showForm && setShowForm(true);
    if (!showForm && selectedAssets.includes(assetArray[0])) return;

    if (currentPage > assetArray.length) {
      return navigate(destinationPage);
    }

    selectedAssets.includes(assetArray[currentPage + 1])
      ? setPage(currentPage + 1)
      : nextPage(currentPage + 1);
  };

  const previousPage: (currentPage: number) => void = (currentPage) => {
    if (currentPage < 0) {
      return navigate(originPage);
    }

    if (currentPage === 0 && showForm) {
      setPage(0);
      return setShowForm(false);
    }

    selectedAssets.includes(assetArray[currentPage - 1])
      ? setPage(currentPage - 1)
      : previousPage(currentPage - 1);
  };

  const handleAssetSelection = (values: Array<string | number>) => {
    const typedValues = values as AssetTypes[];
    // If SHARES is being added (wasn't in previous selection but is in new selection)
    if (!selectedAssets.includes("SHARES") && typedValues.includes("SHARES")) {
      // Add FUNDS to the selection
      setSelectedAssets([...new Set([...typedValues, "FUNDS" as AssetTypes])]);
    }
    // If SHARES is being removed (was in previous selection but not in new selection)
    else if (
      selectedAssets.includes("SHARES") &&
      !typedValues.includes("SHARES")
    ) {
      // Remove both SHARES and FUNDS
      setSelectedAssets(
        typedValues.filter((v) => v !== ("FUNDS" as AssetTypes))
      );
    } else {
      setSelectedAssets(typedValues);
    }
  };

  const RenderPage = pages[assetArray[page]].page;

  switch (true) {
    case showChooseCurrencyPage:
      return (
        <Currency
          showTooltip={showTooltip}
          onClickContinue={() => {
            setShowChooseCurrencyPage(false);
          }}
        />
      );
    case showForm:
      return (
        <RenderPage
          nextPage={() => nextPage(page)}
          previousPage={() => previousPage(page)}
          showTooltip={showTooltip}
        />
      );
    default:
      return (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">What I own</Text>
              <Description>
                Please select the options that you think may apply to you. If
                you&apos;re not sure about an option, select it anyway and more
                information will follow to help you decide.
              </Description>
              <Tooltip
                label="Important Information"
                text="If you have a property to flip, short term shares or SME finance investments please select 'other'."
              />
            </div>
          </InnerWrapper>
          <CheckboxButton
            direction={
              isMobile
                ? CheckboxButtonDirection.VERTICAL
                : CheckboxButtonDirection.HORIZONTAL
            }
            buttons={buttonDefinitions()}
            onChange={handleAssetSelection}
            values={selectedAssets}
          />
          <InnerWrapper>
            <Spacer />
            <FormButton
              active={selectedAssets.length > 0}
              onClick={() => nextPage(page)}
              text="Save & Continue"
              leftArrowOnClick={() => {
                setShowChooseCurrencyPage(true);
              }}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      );
  }
};

export default PortfolioDataV2;
