import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { FETCH_SILVER_PRICE_API } from "../constants/actionTypes";
import { Currency } from "@ifgengineering/hip-app-domain/src";
import { logException } from "@helpers/logException";

export const fetchSilverPriceAPI = createAsyncThunk<
  any,
  { currency: Currency }
>(FETCH_SILVER_PRICE_API, async ({ currency }) => {
  try {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query ($currency: String!) {
          findCommodityPrice(commodityType: "silver", currency: $currency) {
            value
          }
        }
      `,
      variables: {
        currency,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.findCommodityPrice;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "fetchSilverPriceAPI",
      exception: e,
    });

    throw Error(e);
  }
});
