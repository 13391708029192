import React from "react";
import PortfolioDataV1 from "@components/PortfolioData";
import PortfolioDataV2 from "./PortfolioDataV2.component";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const PortfolioData = isZakat2025Enabled ? PortfolioDataV2 : PortfolioDataV1;

const Assets = () => {
  return (
    <PortfolioData
      destinationPage="/app/summary"
      originPage="/app/calculate"
      showTooltip
      showCurrencyPage
      forceAssetsItems
    />
  );
};

export default Assets;
