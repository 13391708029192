import React from "react";
import { PageType } from "../../types";
import PortfolioAssetPage from "../../components/PortfolioAssetPage";

const AlternativeInvestmentsPage: React.FC<PageType> = ({
  nextPage,
  previousPage,
}) => {
  return (
    <PortfolioAssetPage
      nextPage={nextPage}
      previousPage={previousPage}
      assetType="OTHER"
    />
  );
};

export default AlternativeInvestmentsPage;
