import { ZakatAsset, ZakatAssetType } from "@ifgengineering/client-invest-sdk";

export const categorizeAssetsByType = (
  assets: ZakatAsset[]
): Record<ZakatAssetType, ZakatAsset[]> => {
  return assets.reduce((acc, asset) => {
    if (asset.type) {
      if (!acc[asset.type]) {
        acc[asset.type] = [];
      }
      acc[asset.type].push(asset);
    }
    return acc;
  }, {} as Record<ZakatAssetType, ZakatAsset[]>);
};

export const getAssetsWithValue = (assets: ZakatAsset[]): ZakatAssetType[] => {
  const categorizedAssets = categorizeAssetsByType(assets);
  const assetTypes = Object.entries(categorizedAssets)
    .filter(([_, assets]) => assets.length > 0)
    .map(([assetType]) => assetType as ZakatAssetType);

  // If either SHARE or FUND is present, include both
  if (
    assetTypes.includes("SHARE" as ZakatAssetType) ||
    assetTypes.includes("FUND" as ZakatAssetType)
  ) {
    return [
      ...new Set([
        ...assetTypes,
        "SHARE" as ZakatAssetType,
        "FUND" as ZakatAssetType,
      ]),
    ];
  }

  return assetTypes;
};
