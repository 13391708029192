import React, { useContext, useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage as FormikError,
  FieldInputProps,
} from "formik";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import { useDispatch } from "react-redux";
import { CurrencyContext } from "@components/CurrencyContext";
import {
  createZakatLiabilityAsync,
  updateZakatLiabilityAsync,
} from "@Zakat/store/zakatV2/zakat.action";
import PortfolioFormLayout from "./PortfolioFormLayout";
import { ErrorMessage } from "../styled";
import { PortfolioLiabilityFormData } from "./types";
import { PortfolioLiabilitySchema } from "./validationSchema";
import { LiabilityLabels, getLiabilityTypesList } from "../constants/debt";
import DropDown, {
  DropDownValue,
} from "@legacyComponents/DropDown/DropDown.component";
import { ZakatLiabilityType } from "@ifgengineering/client-invest-sdk";

export interface PortfolioLiabilityFormProps {
  data: PortfolioLiabilityFormData;
  previousPage: () => void;
  onSubmit: () => void;
}

const PortfolioLiabilityForm = ({
  data,
  onSubmit,
  previousPage,
}: PortfolioLiabilityFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);
  const [selectedDropdownItem, setSelectedDropdownItem] =
    useState<DropDownValue | null>({
      value: data.type,
      label: LiabilityLabels[data.type || "MORTGAGE"],
    });

  const handleSubmit = async (values: PortfolioLiabilityFormData) => {
    if (values.id) {
      await dispatch(
        updateZakatLiabilityAsync({
          liabilityId: values.id.toString(),
          data: {
            name: values.name,
            description: values.description,
            amount: values.amount,
            type: values.type as ZakatLiabilityType,
          },
        })
      );
    } else {
      await dispatch(
        createZakatLiabilityAsync({
          name: values.name,
          description: values.description,
          amount: values.amount,
          type: values.type as ZakatLiabilityType,
        })
      );
    }
    onSubmit();
  };

  return (
    <Formik
      validationSchema={PortfolioLiabilitySchema}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      <Form>
        <PortfolioFormLayout
          title="Debts"
          description="Enter the amount of debt you owe"
          showTooltip={false}
          onPrevious={previousPage}
          submitButtonText="Save & Continue"
          isFormik={true}
        >
          <Field name="type">
            {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
              <DropDown
                options={getLiabilityTypesList()}
                onChange={(item) => {
                  form.setFieldValue(field.name, item?.value);
                  setSelectedDropdownItem(item || null);
                }}
                heading="Type of Debt"
                value={
                  getLiabilityTypesList().find(
                    (option) => option.value === field.value
                  ) || selectedDropdownItem
                }
              />
            )}
          </Field>

          <Field name="name">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  heading="Name of Debt"
                  description="Enter the name of the debt"
                  placeholder="Enter name"
                />
                <FormikError name="name" component={ErrorMessage} />
              </>
            )}
          </Field>

          <Field name="description">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  heading="Description"
                  description="e.g. my Barclays credit card debt"
                  placeholder="Enter description"
                />
                <FormikError name="description" component={ErrorMessage} />
              </>
            )}
          </Field>

          <Field name="amount">
            {({ field }: { field: FieldInputProps<any> }) => (
              <>
                <MoneyInput
                  {...field}
                  heading="Estimated Value"
                  description="Enter the estimated value of the debt"
                  currency={currencySymbol}
                  value={field.value?.toString() || ""}
                  onChange={(value) => {
                    field.onChange({
                      target: {
                        name: field.name,
                        value: value ? parseFloat(value.replace(/,/g, "")) : "",
                      },
                    });
                  }}
                />
                <FormikError name="amount" component={ErrorMessage} />
              </>
            )}
          </Field>
        </PortfolioFormLayout>
      </Form>
    </Formik>
  );
};

export default PortfolioLiabilityForm;
