import React from "react";
import { PageType } from "../../types";
import PortfolioAssetPage from "../../components/PortfolioAssetPage";

const ChildTrustFundPage: React.FC<PageType> = ({ nextPage, previousPage }) => {
  return (
    <PortfolioAssetPage
      nextPage={nextPage}
      previousPage={previousPage}
      assetType="CHILD_TRUST_FUND"
    />
  );
};

export default ChildTrustFundPage;
